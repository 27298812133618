
import "./CardFooter.css"
function CardFooter() {

    return (
        <footer className="footer" >
            <div>&copy;2022 | copyright nectarina.pl</div>
        </footer>
    )
}

export default CardFooter;